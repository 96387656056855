import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"
import { Link } from "gatsby"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
    text-align: center;
  }

  span {
    color: var(--primary);
  }

  p {
    line-height: 1.6em;
  }
`

const organisation_item = () => {
  return (
    <>
      <Seo title="Transport uczestników uroczystości" />
      <Layout>
        <SimpleBanner title="Transport uczestników uroczystości">
          <StaticImage
            className="banner__image desktop"
            src="../../images/organisation/transport.jpg"
          />
          <StaticImage
            className="banner__image mobile"
            src="../../images/organisation/transport.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            Pragniemy zaoferować Państwu wynajem busów (8+1) i autokarów (51
            osobowych) o bardzo wysokim standardzie. Nasz zespół to grupa
            doświadczonych kierowców z całą wiedzą na temat zapewnienia
            najwyższego standardu usług. Dysponujemy nowoczesną i komfortową
            flotą. Nasza oferta obejmuje m.in:
          </p>
          <ul>
            <li>przewóz Gości weselnych, obsługa wesela</li>
            <li>przewóz Gości do kościoła, na salę weselną</li>
            <li>
              przywóz Gości weselnych do kościoła, pod Urząd Stanu Cywilnego,
              itd.
            </li>
            <li>rozwożenie Gości weselnych</li>
            <li>wynajem busów dla firm / osób prywatnych</li>
            <li>wyjazdy na szkolenia</li>
            <li>transfer na lotniska</li>
            <li>przewozy pracownicze</li>
            <li>obsługa wesel</li>
            <li>wycieczki dla szkół</li>
            <li>przewóz dzieci na basen, do kina, teatru itp.</li>
            <li>wyjazdy na narty, ferie, kolonie, itp.</li>
            <li>wycieczki dla przedszkoli</li>
            <li>obsługa imprez firmowych</li>
            <li>usługi transportowe</li>
          </ul>
          <p className="link-container">
            <Link to="/contact">Zapytaj nas o szczegóły</Link>
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default organisation_item
